<template>
    <div class="footer">
        <div class="d-none d-sm-flex justify-space-between align-center">
            <div>
                <div class="d-flex justify-start align-center">
                    <div>
                        <v-img width="38" contain src="@/assets/images/logo.png"></v-img>
                    </div>
                    <div class="text-h5 white--text font-weight-bold ml-2">Life Sciences Cells Bank Foundation</div>
                </div>
                <div class="white--text text-caption white--text mt-5" style="max-width:700px;">
                    {{ $t('Page.Footer.Desc') }}
                </div>
                <div class="d-flex justify-start align-center text-body-2 mt-7">
                    <v-icon color="#AC8250" size="20" class="mr-1">mdi-email-outline</v-icon>
                    <a href="mailto:partnerships@supercells.pro" class="email">{{ $t('Page.Footer.con1') }}：partnerships@supercells.pro</a>
                </div>
                <div class="d-flex justify-start align-center text-body-2 mt-3">
                    <v-icon color="#AC8250" size="20" class="mr-1">mdi-email-outline</v-icon>
                    <a href="mailto:support@supercells.pro" class="email">{{ $t('Page.Footer.con2') }}：support@supercells.pro</a>
                </div>
            </div>
            <div>
                <div class="d-flex justify-end">
                    <a href="https://twitter.com/SuperCellsFUND" target="_blank" class="d-block">
                    <v-img contain width="40" src="@/assets/images/twitter_a.svg"></v-img>
                    </a>
                    <a href="https://discord.gg/tXrfZfCE5G" target="_blank" class="d-block ml-10">
                        <v-img contain width="40" src="@/assets/images/discord_a.svg"></v-img>
                    </a>
                    <a href="https://t.me/supercells_foundation" target="_blank" class="d-block ml-10">
                        <v-img contain width="40" src="@/assets/images/telegram_a.svg"></v-img>
                    </a>
                    <a href="https://www.youtube.com/@supercellsfoundation/channels" target="_blank" class="d-block ml-10">
                        <v-img contain width="40" src="@/assets/images/youtube.png"></v-img>
                    </a>
                </div>
            </div>
        </div>

        <div class="d-block d-sm-none">
            <div class="d-flex justify-space-between">
                <div class="d-flex justify-start align-center">
                    <v-img width="35" contain src="@/assets/images/logo.png"></v-img>
                    <div class="text-body-1 font-weight-bold ml-2">Life Sciences Cells Bank Foundation</div>
                </div>
                <div class="d-flex justify-end">
                    <a href="https://twitter.com/SuperCellsFUND" target="_blank" class="d-block">
                        <v-img contain width="28" src="@/assets/images/twitter_a.svg"></v-img>
                    </a>
                    <a href="https://discord.gg/tXrfZfCE5G" target="_blank" class="d-block ml-5">
                        <v-img contain width="28" src="@/assets/images/discord_a.svg"></v-img>
                    </a>
                    <a href="https://t.me/supercells_foundation" target="_blank" class="d-block ml-5">
                        <v-img contain width="28" src="@/assets/images/medium_a.svg"></v-img>
                    </a>
                    <a href="https://www.youtube.com/@supercellsfoundation/channels" target="_blank" class="d-block ml-5">
                        <v-img contain width="28" src="@/assets/images/youtube.png"></v-img>
                    </a>
                </div>
            </div>
            <div class="white--text text-body-1 mt-6" style="max-width:450px;">{{ $t('Page.Footer.Desc') }}</div>
            <div class="d-flex justify-start align-center text-body-2 mt-7">
                <v-icon color="#AC8250" size="20" class="mr-1">mdi-email-outline</v-icon>
                <a href="mailto:partnerships@supercells.pro" class="email">{{ $t('Page.Footer.con1') }}：partnerships@supercells.vip</a>
            </div>
            <div class="d-flex justify-start align-center text-body-2 mt-3">
                <v-icon color="#AC8250" size="20" class="mr-1">mdi-email-outline</v-icon>
                <a href="mailto:support@supercells.pro" class="email">{{ $t('Page.Footer.con2') }}：support@supercells.vip</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="scss">
.footer {
    padding: 48px 40px 70px;
    background: #221816;
    color: #AC8250;
    overflow-x: auto;

    .logo-block {
      width: 39px;
      height: 39px;
      background: #AC8250;
      border-radius: 50%;
    }

    a.email {
        color: #AC8250;
    }
}


@media screen and (max-width: 960px) {
    .footer {
        padding: 30px 20px 54px;
        background: #221816;

        .logo-block {
            width: 30px;
            height: 30px;
        }
    }
}
</style>